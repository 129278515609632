import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { MemorialBookStatus } from '../api/memorial-book/dto/memorial-book-response.dto';

import {
    getIsBookGenerationInProgress,
    getIsBookGenerationPossible,
    getIsMemorialBookGenerationRequestSend,
    getMemorialBookData,
    getMemorialBookGenerationErrorStatus,
    getMemorialBookValidationErrors,
} from './model/selectors/memorial-book.selector';
import {
    generateMemorialBookActions,
    validateMemorialBookGenerationActions,
} from './model/memorial-book.actions';

export const useMemorialBookState = () => {
    const dispatch = useDispatch();
    const bookGenerationPossible = useSelector(getIsBookGenerationPossible);
    const bookGenerationRequestInProgress = useSelector(
        getIsBookGenerationInProgress,
    );
    const bookGenerationRequestSend = useSelector(
        getIsMemorialBookGenerationRequestSend,
    );
    const bookGenerationErrorStatus = useSelector(
        getMemorialBookGenerationErrorStatus,
    );
    const memorialBookData = useSelector(getMemorialBookData);
    const bookValidationErrors = useSelector(getMemorialBookValidationErrors);

    useEffect(() => {
        if (
            !memorialBookData ||
            !memorialBookData.status ||
            memorialBookData.status === MemorialBookStatus.INITIAL
        ) {
            dispatch(validateMemorialBookGenerationActions.request());
        }
    }, [dispatch, memorialBookData]);

    const generateMemorialBook = useCallback(() => {
        dispatch(generateMemorialBookActions.request());
    }, [dispatch]);

    return {
        bookGenerationPossible,
        bookGenerationRequestInProgress,
        bookGenerationRequestSend,
        bookGenerationErrorStatus,
        memorialBookData,
        bookValidationErrors,
        generateMemorialBook,
    };
};
