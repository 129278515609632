import { createSelector } from 'reselect';

import { RootStore } from '../../../store/store';

export const getMemorialBookStore = (state: RootStore) => state.memorialBook;

export const getIsBookGenerationInProgress = createSelector(
    getMemorialBookStore,
    (store) => store.bookGenerationInProgress,
);

export const getIsBookGenerationPossible = createSelector(
    getMemorialBookStore,
    (store) => store.bookGenerationPossible,
);

export const getIsMemorialBookGenerationRequestSend = createSelector(
    getMemorialBookStore,
    (store) => store.generateMemorialBookRequestSend,
);

export const getMemorialBookGenerationErrorStatus = createSelector(
    getMemorialBookStore,
    (store) => store.generationError,
);

export const getMemorialBookData = createSelector(
    getMemorialBookStore,
    (store) => store.memorialBook,
);

export const getMemorialBookValidationErrors = createSelector(
    getMemorialBookStore,
    (store) => store.validationErrors,
);

export const getMemorialBookEnabledFlag = createSelector(
    getMemorialBookStore,
    (store) => store.memorialBookEnabled,
);
