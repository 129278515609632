import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { OutlinedButton } from '../components/outlined-button/outlined-button';
import { getPageSlug } from '../meta/model/selectors/get-page-slug.selector';

const useStyles = makeStyles((theme) => ({
    preview: {
        width: '100%',
        height: '100%',
        minHeight: '500px',
    },
    orderButton: {
        marginTop: '2rem',
    },
}));

export const ApprovedMemorialBook = ({
    memorialBookLink,
    memorialBookTitle,
}: {
    memorialBookLink: string;
    memorialBookTitle: string;
}) => {
    const { t } = useTranslation();
    const styles = useStyles();
    const slug = useSelector(getPageSlug);

    return (
        <>
            <iframe
                className={styles.preview}
                src={memorialBookLink}
                title={memorialBookTitle}
            />
            <OutlinedButton
                className={styles.orderButton}
                onClick={() => {
                    return window.open(
                        `${process.env.REACT_APP_MEMORIAL_BASE_URL}/${slug}/memorial-book/overview#nav`,
                        '_blank',
                    );
                }}
            >
                {t('memorialBook.orderButton')}
            </OutlinedButton>
        </>
    );
};
