import { delay, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { generateMemorialBookApiActions } from '../../../api/memorial-book/memorial-book.api.actions';
import {
    generateMemorialBookActions,
    generateMemorialBookDataReceived,
} from '../memorial-book.actions';

type PossibleApiResults =
    | ReturnType<typeof generateMemorialBookApiActions.success>
    | ReturnType<typeof generateMemorialBookApiActions.failure>;

export function* generateMemorialBookSaga(
    action: ReturnType<typeof generateMemorialBookActions.request>,
) {
    yield put(generateMemorialBookApiActions.request());

    const result: PossibleApiResults = yield take([
        generateMemorialBookApiActions.success,
        generateMemorialBookApiActions.failure,
    ]);

    switch (result.type) {
        case getType(generateMemorialBookApiActions.success):
            yield put(generateMemorialBookActions.success(result.payload.id));
            // wait 10s till switching to status started
            yield delay(10000);
            yield put(
                generateMemorialBookDataReceived(result.payload.attributes),
            );
            break;
        case getType(generateMemorialBookApiActions.failure):
            yield put(generateMemorialBookActions.failure(result.payload));
            break;
        default:
            yield;
    }
}
