import { takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
    generateMemorialBookApiActions,
    validateMemorialBookGenerationApiActions,
} from '../memorial-book.api.actions';

import { generateMemorialBookApiSaga } from './generate-memorial-book-api.saga';
import { validateMemorialBookApiSaga } from './validate-memorial-book-api.saga';

export function* memorialBookApiSaga() {
    yield takeEvery(
        getType(generateMemorialBookApiActions.request),
        generateMemorialBookApiSaga,
    );

    yield takeLatest(
        getType(validateMemorialBookGenerationApiActions.request),
        validateMemorialBookApiSaga,
    );
}
