import React, { ChangeEvent, useRef } from 'react';
import { Box, Button, Grid, makeStyles, Theme } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { compose } from 'recompose';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';

import { StepHeadline } from '../components/step-headline/step-headline';
import { PrimaryButton } from '../../components/primary-button/primary-button';
import { WizardContentContainer } from '../components/wizard-content-container/wizard-content-container';
import { useIsMobile } from '../../../styles/use-is-mobile';
import { ImageCropModal } from '../../components/image-crop-modal/image-crop-modal';
import { useDeceasedPersonData } from '../../model/deceased-person/use-deceased-person-data';
import { useWizardStep } from '../use-wizard-step';
import { DeceasedDisplay } from '../../components/deceased-display/deceased-display';

import { PhotoButton } from './components/photo-button/photo-button';
import { SelectBackgroundModal } from './components/select-background-modal/select-background-modal';
import { useWizardPhotosScreenState } from './use-wizard-photos-screen-state';

type Props = RouteComponentProps;

const useStyles = makeStyles<Theme>((theme) => ({
    headline: {
        marginBottom: theme.spacing(6),
    },
    uploadBox: {
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            padding: '30px',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        },
    },
    avatarButton: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            marginRight: theme.spacing(2),
        },
    },
    buttonsContainer: {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(3),
        },
    },
    photoButtonsContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
}));

export const WizardPhotosScreen = () => {
    const { t } = useTranslation();
    const {
        closeBgModal,
        openBgModal,
        bgModalOpen,
        setSelectedBackgroundID,
        selectedBackgroundID,
        availableBackgrounds,
        avatarPreview,
        skipStep,
        submit,
        imageCropModalOpen,
        setImageCropModalOpen,
        selectedFile,
        setSelectedFile,
    } = useWizardPhotosScreenState();

    const { deceasedPersonData } = useDeceasedPersonData();
    const wizardStepProgress = useWizardStep();

    const getBackgroundImageSource = () => {
        const selectedBgObject = availableBackgrounds.find(
            (image) => image.id === selectedBackgroundID,
        );

        return selectedBgObject ? `url("${selectedBgObject.src}")` : '';
    };

    const styles = useStyles();
    const avatarUploadRef = useRef<HTMLInputElement>(null);

    const mobile = useIsMobile();

    const onChangeAvatarClick = () => {
        avatarUploadRef.current!.click();
    };

    const onAvatarUploaded = (event: ChangeEvent<HTMLInputElement>) => {
        if (!get(event, 'target.files.length')) {
            return;
        }

        const file = event.target!.files![0] as File;

        setSelectedFile(file);
        setImageCropModalOpen(true);

        // eslint-disable-next-line
        event.target.value = ''; // to prevent blocking same file upload, any cleaner solution?
    };

    return (
        <WizardContentContainer>
            <StepHeadline
                data-testid="wizard-photos-step-headline"
                text={t('photos.headline')}
                stepNo={wizardStepProgress + 2}
                className={styles.headline}
            />
            <Box
                mb={2}
                data-testid="wizard-photos-background-box"
                className={styles.uploadBox}
                style={{
                    backgroundImage: getBackgroundImageSource(),
                }}
            >
                <DeceasedDisplay
                    avatarPreview={avatarPreview}
                    selectedBackgroundSrc={getBackgroundImageSource()}
                    deceasedPersonData={deceasedPersonData}
                    avatarPreviewTestId="wizard-photos-avatar-preview"
                />
            </Box>
            <input
                ref={avatarUploadRef}
                type="file"
                hidden
                accept="image/jpeg,image/png"
                max={1}
                onChange={onAvatarUploaded}
            />
            <Box className={styles.photoButtonsContainer}>
                <PhotoButton
                    data-testid="wizard-photos-step-change-photo"
                    active={Boolean(selectedBackgroundID)}
                    className={styles.avatarButton}
                    fullWidth={mobile}
                    onClick={onChangeAvatarClick}
                >
                    {t('photos.changePicture')}
                </PhotoButton>
                <PhotoButton
                    active={Boolean(selectedBackgroundID)}
                    onClick={openBgModal}
                    data-testid="wizard-photos-change-background-button"
                    fullWidth={mobile}
                >
                    {t('photos.selectBackground')}
                </PhotoButton>
            </Box>
            <Grid
                container
                justify="flex-end"
                className={styles.buttonsContainer}
            >
                <Grid
                    item
                    xs={mobile ? 12 : undefined}
                    style={{
                        order: mobile ? 1 : undefined,
                    }}
                >
                    <Button
                        fullWidth={mobile}
                        data-testid="wizard-photos-skip-button"
                        size="large"
                        color="primary"
                        onClick={skipStep}
                    >
                        {t('common.skipThisStep')}
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={mobile ? 12 : undefined}
                    style={{
                        order: mobile ? 0 : undefined,
                    }}
                >
                    <PrimaryButton
                        data-testid="wizard-photos-step-submit"
                        onClick={submit}
                        type="submit"
                        fullWidth={mobile}
                    >
                        {t('common.continue')}
                    </PrimaryButton>
                </Grid>
            </Grid>
            <SelectBackgroundModal
                fullScreen={mobile}
                data-testid="wizard-photos-bg-modal"
                backgroundImages={availableBackgrounds}
                onSelected={(id) => {
                    closeBgModal();
                    setSelectedBackgroundID(id);
                }}
                open={bgModalOpen}
                selectedID={selectedBackgroundID}
                onCancel={closeBgModal}
            />
            <ImageCropModal
                cancellable
                fileName={get(selectedFile, 'name', '')}
                fullScreen={mobile}
                image={avatarPreview}
                onCancel={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onClose={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onCrop={(file) => {
                    setSelectedFile(file);
                    setImageCropModalOpen(false);
                }}
                onSkipCropping={() => setImageCropModalOpen(false)}
                open={imageCropModalOpen}
            />
        </WizardContentContainer>
    );
};

export const ComposedWizardPhotosScreen = compose<Props, Props>()(
    WizardPhotosScreen,
);
