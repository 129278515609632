import { call, select, put } from 'redux-saga/effects';

import { generateMemorialBook } from '../http/generate-memorial-book';
import { generateMemorialBookApiActions } from '../memorial-book.api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { IGenerateMemorialResponseDto } from '../dto/generate-memorial-book.dto';
import { IJsonApiError } from '../dto/memorial-book-response.dto';

export function* generateMemorialBookApiSaga(
    action: ReturnType<typeof generateMemorialBookApiActions.request>,
) {
    const slug: string = yield select(getPageSlug);

    try {
        const response: IGenerateMemorialResponseDto = yield call(
            generateMemorialBook,
            slug,
        );
        yield put(generateMemorialBookApiActions.success(response));
    } catch (e) {
        const resp: IJsonApiError = yield e.response.json();

        if ([500, 501, 502, 503, 504].includes(resp.errors[0].status)) {
            yield put(
                generateMemorialBookApiActions.failure(
                    'memorialBook.general_error',
                ),
            );
        } else {
            yield put(
                generateMemorialBookApiActions.failure(
                    `memorialBook.${resp.errors[0].code}`,
                ),
            );
        }
    }
}
