import { createAction, createAsyncAction } from 'typesafe-actions';

import { IMemorialBook } from '../../api/memorial-book/dto/memorial-book-response.dto';

export const generateMemorialBookActions = createAsyncAction(
    'editor/memorial-book/GENERATE_MEMORIAL_BOOK_REQUESTED',
    'editor/memorial-book/GENERATE_MEMORIAL_BOOK_SUCCEED',
    'editor/memorial-book/GENERATE_MEMORIAL_BOOK_FAILED',
)<undefined, string, string>();

export const validateMemorialBookGenerationActions = createAsyncAction(
    'editor/memorial-book/VALIDATE_MEMORIAL_BOOK_GENERATION_REQUESTED',
    'editor/memorial-book/VALIDATE_MEMORIAL_BOOK_GENERATION_SUCCEED',
    'editor/memorial-book/VALIDATE_MEMORIAL_BOOK_GENERATION_FAILED',
)<undefined, boolean, string[]>();

export const generateMemorialBookDataReceived = createAction(
    'api/memorial-book/GENERATE_MEMORIAL_BOOK_DATA_RECEIVED',
    (action) => (payload: IMemorialBook) => action(payload),
);
