import React, { useImperativeHandle, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';
import 'react-quill/dist/quill.snow.css';

export interface IRichTextEditorProps {
    className?: string;
    minHeight?: string;
    minWidth?: string;
    name: string;
    onChange: (value: {
        target: { name: string; value: string | null };
    }) => unknown;
    placeholder?: string;
    value?: string | null;
    disabled?: boolean;
    maxContentRows?: number;
}

export interface IStylesProps {
    minHeight?: string;
    minWidth?: string;
}

export interface IRichTextEditorRef {
    getText: () => string;
}

const useStyles = makeStyles((theme) => ({
    root: (props: IStylesProps) => ({
        '& .ql-container > .ql-editor': {
            minHeight: props.minHeight,
            minWidth: props.minWidth,
            [theme.breakpoints.down('sm')]: {
                minWidth: 'auto',
            },
        },
    }),
}));

const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
];

export const RichTextEditor = React.forwardRef<
    IRichTextEditorRef,
    IRichTextEditorProps
>(
    (
        {
            className,
            minHeight = '40rem',
            minWidth = 'auto',
            name,
            onChange,
            placeholder,
            value,
            disabled,
            maxContentRows,
        },
        ref,
    ) => {
        const richTextEditorRef = useRef<ReactQuill>(null);
        useImperativeHandle(ref, () => {
            return {
                // used to retrieve plain text without the html tags
                getText: () => {
                    if (richTextEditorRef && richTextEditorRef.current) {
                        const editor = richTextEditorRef.current.getEditor();
                        if (editor) {
                            return editor.getText();
                        }
                    }
                    return '';
                },
            };
        });

        const styles = useStyles({ minHeight, minWidth });
        const [isAllTextSelected, setIsAllTextSelected] = useState<boolean>(
            false,
        );

        const onChangeState = (state: string, hasContent: boolean) => {
            const newValue = hasContent ? state : '';
            const oldValueClosedTagsNumber = newValue.match(
                /<[A-Za-z0-9]{1,}>(.*?)<\/[A-Za-z0-9]{1,}>/g,
            );
            const limitedRowsValue =
                maxContentRows &&
                oldValueClosedTagsNumber &&
                oldValueClosedTagsNumber.length &&
                oldValueClosedTagsNumber.length > maxContentRows
                    ? value
                    : newValue;

            onChange({ target: { name, value: limitedRowsValue || '' } });
        };

        return (
            <ReactQuill
                readOnly={disabled}
                ref={richTextEditorRef}
                className={cx(styles.root, className)}
                onChange={(state, delta, source, editor) => {
                    onChangeState(state, Boolean(editor.getText().trim()));
                }}
                onChangeSelection={(range, source, editor) => {
                    if (
                        range &&
                        range.length > 0 &&
                        range.length === editor.getText().trim().length
                    ) {
                        setIsAllTextSelected(true);
                    } else {
                        setIsAllTextSelected(false);
                    }
                }}
                onKeyPress={(e) => {
                    if (isAllTextSelected) {
                        setIsAllTextSelected(false);
                        onChange({ target: { name, value: e.key } });
                    }
                }}
                placeholder={placeholder}
                value={value || ''}
                defaultValue=""
                modules={{
                    toolbar: toolbarOptions,
                }}
            />
        );
    },
);
