import { createAction, createAsyncAction } from 'typesafe-actions';

import { IMemorialBookDto } from './dto/memorial-book-response.dto';
import { IGenerateMemorialResponseDto } from './dto/generate-memorial-book.dto';

export const generateMemorialBookApiActions = createAsyncAction(
    'api/memorial-book/GENERATE_MEMORIAL_BOOK_REQUESTED',
    'api/memorial-book/GENERATE_MEMORIAL_BOOK_SUCCEEDED',
    'api/memorial-book/GENERATE_MEMORIAL_BOOK_FAILED',
)<undefined, IGenerateMemorialResponseDto, string>();

export const validateMemorialBookGenerationApiActions = createAsyncAction(
    'api/memorial-book/VALIDATE_MEMORIAL_BOOK_GENERATION_REQUESTED',
    'api/memorial-book/VALIDATE_MEMORIAL_BOOK_GENERATION_SUCCEEDED',
    'api/memorial-book/VALIDATE_MEMORIAL_BOOK_GENERATION_FAILED',
)<undefined, boolean, string[]>();

export const memorialBookDataReceived = createAction(
    'api/memorial-book/DATA_RECEIVED',
    (action) => (memorialBookData: IMemorialBookDto) =>
        action(memorialBookData.attributes),
);

export const memorialBookEnabledFlagReceived = createAction(
    'api/memorial-book/ENABLED_FLAG_RECEIVED',
    (action) => (payload: boolean) => action(payload),
);
