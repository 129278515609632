import { call, put, select } from 'redux-saga/effects';

import { validateMemorialBookGeneration } from '../http/validate-memorial-book-generation';
import { validateMemorialBookGenerationApiActions } from '../memorial-book.api.actions';
import { getPageSlug } from '../../../meta/model/selectors/get-page-slug.selector';
import { IJsonApiError } from '../dto/memorial-book-response.dto';

export function* validateMemorialBookApiSaga(
    action: ReturnType<typeof validateMemorialBookGenerationApiActions.request>,
) {
    const slug: string = yield select(getPageSlug);

    try {
        const response: boolean = yield call(
            validateMemorialBookGeneration,
            slug,
        );
        yield put(validateMemorialBookGenerationApiActions.success(response));
    } catch (e) {
        const resp: IJsonApiError = yield e.response.json();
        yield put(
            validateMemorialBookGenerationApiActions.failure(
                resp.errors.map((c) => c.title),
            ),
        );
    }
}
