import React, { ChangeEvent, ReactNode, useRef } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Resizer from 'react-image-file-resizer';

import { OutlinedButton } from '../../../components/outlined-button/outlined-button';
import { Section, ISectionProps } from '../../components/section/section';
import { useIsMobile } from '../../../../styles/use-is-mobile';
import { SelectBackgroundModal } from '../../../wizard/photos-step/components/select-background-modal/select-background-modal';
import { ImageCropModal } from '../../../components/image-crop-modal/image-crop-modal';
import { useDeceasedPersonData } from '../../../model/deceased-person/use-deceased-person-data';
import { deceasedDisplayImagesAvatarReceived } from '../../../model/deceased-display-images/deceased-display-images.actions';
import { DeceasedDisplay } from '../../../components/deceased-display/deceased-display';

import { useEditorPhotos } from './use-editor-photos';

interface IPhotosSectionProps extends ISectionProps {
    /**
     * Technically this is bad design of components, because
     * these components are hardcoded in sections.
     *
     * Its parent (screen/page) which should use sections and put there
     * children.
     *
     * Its big refactor so just inject a child for now
     */
    topContent?: ReactNode;
}

const useStyles = makeStyles<Theme, { backgroundSrc: string | null }>(
    (theme) => ({
        container: {
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                padding: '30px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: ({ backgroundSrc }) => {
                    if (backgroundSrc) {
                        return `url("${backgroundSrc}")`;
                    }
                    return '';
                },
            },
        },
        button: {
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '21rem',
            },
        },
        backgroundButton: {
            marginTop: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(2),
                marginTop: 0,
            },
        },
        buttonsContainer: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
                flexDirection: 'row',
                justifyContent: 'center',
            },
        },
    }),
);

export const PhotosSection = (props: IPhotosSectionProps) => {
    const { t } = useTranslation();
    const mobile = useIsMobile();
    const {
        avatarPreview,
        availableBackgrounds,
        backgroundModalOpen,
        selectedBackgroundID,
        setSelectedBackgroundID,
        backgroundPhotoChangeRequested,
        closeBackgroundPhotoChangeModalRequested,
        sendBackgroundImageId,
        selectedBackgroundSrc,
        uploadFile,
        imageCropModalOpen,
        setImageCropModalOpen,
        selectedFile,
        setSelectedFile,
    } = useEditorPhotos();

    const styles = useStyles({
        backgroundSrc: selectedBackgroundSrc,
    });

    const { deceasedPersonData } = useDeceasedPersonData();

    const avatarUploadRef = useRef<HTMLInputElement>(null);
    const avatarImageRef = useRef<HTMLImageElement>(null);

    const onChangeAvatarClick = () => {
        avatarUploadRef.current!.click();
    };

    const dispatch = useDispatch();

    const onAvatarUploaded = (event: ChangeEvent<HTMLInputElement>) => {
        if (!get(event, 'target.files.length')) {
            return;
        }

        const file = event.target!.files![0] as File;

        if (file) {
            Resizer.imageFileResizer(
                file,
                740,
                740,
                'JPEG',
                100,
                0,
                (uri) => {
                    const resizedFile = new File([uri as Blob], file.name);
                    setSelectedFile(resizedFile);
                },
                'blob',
                600,
                600,
            );
        }

        setImageCropModalOpen(true);

        // eslint-disable-next-line
        event.target.value = ''; // to prevent blocking same file upload, any cleaner solution?
    };

    return (
        <Section {...props}>
            {props.topContent}
            <Box
                data-testid="overview-deceased-container"
                className={styles.container}
            >
                <DeceasedDisplay
                    avatarPreview={avatarPreview}
                    selectedBackgroundSrc={selectedBackgroundSrc}
                    deceasedPersonData={deceasedPersonData}
                />
            </Box>
            <Box className={styles.buttonsContainer}>
                <OutlinedButton
                    data-testid="deceased-change-avatar"
                    className={styles.button}
                    onClick={onChangeAvatarClick}
                >
                    {t('overview.changePhotoButton')}
                </OutlinedButton>
                <input
                    ref={avatarUploadRef}
                    type="file"
                    hidden
                    accept="image/jpeg,image/png"
                    max={1}
                    onChange={onAvatarUploaded}
                />
                {(availableBackgrounds.length > 1 || !selectedBackgroundID) && (
                    <OutlinedButton
                        data-testid="deceased-change-background"
                        className={cx(styles.button, styles.backgroundButton)}
                        onClick={backgroundPhotoChangeRequested}
                    >
                        {t('overview.changeBackgroundButton')}
                    </OutlinedButton>
                )}
            </Box>
            <SelectBackgroundModal
                fullScreen={mobile}
                data-testid="wizard-photos-bg-modal"
                backgroundImages={availableBackgrounds}
                onSelected={(id) => {
                    if (id) {
                        sendBackgroundImageId(id);
                    }
                    setSelectedBackgroundID(id);
                    closeBackgroundPhotoChangeModalRequested();
                }}
                open={backgroundModalOpen}
                selectedID={selectedBackgroundID}
                onCancel={closeBackgroundPhotoChangeModalRequested}
            />
            <ImageCropModal
                cancellable
                fileName={get(selectedFile, 'name', '')}
                fullScreen={mobile}
                image={avatarPreview}
                onCancel={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onClose={() => {
                    setSelectedFile(null);
                    setImageCropModalOpen(false);
                }}
                onCrop={(file) => {
                    setSelectedFile(file);
                    uploadFile(file);
                    setImageCropModalOpen(false);

                    if (avatarImageRef && avatarImageRef.current) {
                        dispatch(
                            deceasedDisplayImagesAvatarReceived(
                                avatarImageRef.current.src,
                            ),
                        );
                    }
                }}
                open={imageCropModalOpen}
            />
        </Section>
    );
};
