import React from 'react';
import {
    Backdrop,
    Box,
    CircularProgress,
    List,
    ListItem,
    makeStyles,
    Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Block } from '@material-ui/icons';

import { Colors } from '../../styles/colors';
import { ReactComponent as SuccessIcon } from '../assets/icons/success.svg';
import { OutlinedButton } from '../components/outlined-button/outlined-button';

import { useMemorialBookState } from './use-memorial-book-state';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        color: '#fff',
        zIndex: theme.zIndex.drawer + 1,
    },
    accentText: {
        color: Colors.DODGER_BLUE,
        fontSize: 24,
        fontWeight: 500,
        margin: '25px 0 15px',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '60px 0',
        alignItems: 'center',
    },
    errorMessage: {
        color: 'red',
        marginTop: '5px',
    },
    validationFailed: {
        marginBottom: '24px',
    },
    validationMessages: {
        color: 'red',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
    },
    listText: {
        lineHeight: 1,
    },
}));

export const GenerateMemorialBook = () => {
    const { t } = useTranslation();
    const styles = useStyles();

    const {
        bookGenerationPossible,
        bookGenerationRequestSend,
        bookGenerationRequestInProgress,
        bookGenerationErrorStatus,
        bookValidationErrors,
        generateMemorialBook,
    } = useMemorialBookState();

    return (
        <>
            {bookGenerationRequestSend ? (
                <Box className={styles.container}>
                    <SuccessIcon />
                    <Typography className={styles.accentText}>
                        {t('memorialBook.sendTitle')}
                    </Typography>
                    <Typography>{t('memorialBook.sendSubtitle')}</Typography>
                </Box>
            ) : (
                <>
                    {bookValidationErrors && bookValidationErrors.length > 0 && (
                        <Box className={styles.validationFailed}>
                            <Typography>
                                {t('memorialBook.validationFailed')}
                            </Typography>
                            <List>
                                {bookValidationErrors.map((el) => {
                                    return (
                                        <ListItem
                                            key={el}
                                            className={
                                                styles.validationMessages
                                            }
                                        >
                                            <Block />
                                            <Typography
                                                className={styles.listText}
                                            >
                                                {el}
                                            </Typography>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    )}
                    <OutlinedButton
                        onClick={() => generateMemorialBook()}
                        disabled={
                            bookGenerationRequestInProgress ||
                            !bookGenerationPossible ||
                            (!!bookValidationErrors &&
                                bookValidationErrors.length > 0)
                        }
                    >
                        {t('memorialBook.generateButton')}
                    </OutlinedButton>
                    {bookGenerationErrorStatus && (
                        <div className={styles.errorMessage}>
                            {t(`${bookGenerationErrorStatus}`)}
                        </div>
                    )}
                </>
            )}
            <Backdrop
                className={styles.backdrop}
                open={bookGenerationRequestInProgress}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
