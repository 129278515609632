import React, { useRef } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useDateFormatter } from '../../utils/use-date-formatter';
import { IDeceasedPersonData } from '../../interfaces/deceased-person-data';

interface IDeceasedDisplayProps {
    avatarPreview: string | null;
    selectedBackgroundSrc: string | null;
    deceasedPersonData: IDeceasedPersonData;
    avatarPreviewTestId?: string;
}

const useStyles = makeStyles<Theme, { backgroundSrc: string | null }>(
    (theme) => ({
        infoContainer: {
            background: '#f8fce7',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            border: '1px solid #282e28',
            height: 'auto',
            position: 'relative',
            alignItems: 'center',
            margin: '0 auto',
            width: '100%',

            [theme.breakpoints.up('sm')]: {
                width: '90%',
            },

            [theme.breakpoints.up('md')]: {
                width: '80%',
                boxShadow: '8px 8px 0 #282e28',
                padding: '2rem 3rem',
            },
        },
        deceasedDisplayDateAndName: {
            fontFamily: `'Elanor', serif`,
            fontWeight: 300,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
        },
        deceasedDisplayDates: {
            textTransform: 'uppercase',
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
            borderBottom: '1px solid #282e28',

            [theme.breakpoints.up('md')]: {
                border: 'none',
            },
        },
        verticalSeparator: {
            width: '1px',
            backgroundColor: '#282e28',

            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        avatarImage: {
            width: '100%',
            minHeight: '200px',
        },
        name: {
            fontWeight: 200,
            fontSize: '50px',
            margin: '10px 0',
            textAlign: 'center',
        },
        date: {
            fontSize: '12px',
            padding: '20px 0',

            [theme.breakpoints.up('sm')]: {
                fontSize: '16px',
            },
        },
    }),
);

export const DeceasedDisplay = ({
    deceasedPersonData,
    selectedBackgroundSrc,
    avatarPreview,
    avatarPreviewTestId = 'deceased-avatar-photo',
}: IDeceasedDisplayProps) => {
    const { t } = useTranslation();

    const styles = useStyles({
        backgroundSrc: selectedBackgroundSrc,
    });

    const { getShortDate } = useDateFormatter();

    const avatarImageRef = useRef<HTMLImageElement>(null);

    return (
        <Box className={styles.infoContainer}>
            <Box className={styles.deceasedDisplayDateAndName}>
                <Box className={styles.deceasedDisplayDates}>
                    <Box
                        data-testid="deceased-birth"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        className={styles.date}
                    >
                        <span>{t('common.birth')}</span>
                        <span>
                            {deceasedPersonData.birthDate
                                ? getShortDate(deceasedPersonData.birthDate)
                                : '-'}
                        </span>
                    </Box>
                    <div className={styles.verticalSeparator} />
                    <Box
                        data-testid="deceased-death"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        className={styles.date}
                    >
                        <span>{t('common.death')}</span>
                        <span>
                            {deceasedPersonData.deathDate
                                ? getShortDate(deceasedPersonData.deathDate)
                                : '-'}
                        </span>
                    </Box>
                </Box>
                <Box data-testid="deceased-name" className={styles.name}>
                    <span>{deceasedPersonData.name}</span>
                </Box>
            </Box>
            {avatarPreview && (
                <img
                    alt=""
                    className={styles.avatarImage}
                    src={avatarPreview}
                    ref={avatarImageRef}
                    data-testid={avatarPreviewTestId}
                />
            )}
        </Box>
    );
};
