import { call, put } from 'redux-saga/effects';
import { get } from 'lodash';

import { fetchPageActions } from '../page-api.actions';
import { fetchMemorialPageData } from '../fetch-memorial-page-data';
import { IMemorialPageMetaDataResponse } from '../dto/memorial-page-data-response.dto';
import { memorialPageCreationStepReceived } from '../../../meta/model/meta.actions';
import { MemorialPageCreationStep } from '../../../interfaces/memorial-page-creation-step';
import { deceasedDataReceived } from '../../deceased/deceased-api.actions';
import {
    pageSettingsDataRetrieved,
    pagePrivacyModeRetrieved,
    pagePublishedStatusRetrieved,
} from '../../../settings/model/settings.actions';
import { PrivacyMode } from '../../../interfaces/privacy-mode';
import { deceasedDisplayImagesReceived } from '../../../model/deceased-display-images/deceased-display-images.actions';
import { tributeContentReceived } from '../../../model/tribute/tribute-model.actions';
import { obituaryReceived } from '../../../model/obituary/obituary-model.actions';
import { notificationMessageTextReceived } from '../../../model/notification-message/notification-messages-model.actions';
import { languageRetrieved } from '../../../model/configuration/configuration.actions';
import { SupportedLanguageCode } from '../../../interfaces/supported-language-code';
import { themeReceived } from '../../../model/theme/theme.actions';
import { vgReceived } from '../../../model/vg/vg.actions';
import {
    memorialBookDataReceived,
    memorialBookEnabledFlagReceived,
} from '../../memorial-book/memorial-book.api.actions';
import { IMemorialBookDto } from '../../memorial-book/dto/memorial-book-response.dto';

// TODO: Move partial actions to somewhere else
export function* fetchPageSaga(
    action: ReturnType<typeof fetchPageActions.request>,
) {
    try {
        const response: IMemorialPageMetaDataResponse = yield call(
            fetchMemorialPageData,
            action.payload.slug,
        );

        yield put(
            memorialPageCreationStepReceived({
                state: response.data.attributes
                    .currentWizardStep as MemorialPageCreationStep,
            }),
        );

        const deceasedData = response.included.find(
            (incl) => incl.type === 'deceased_person',
        );

        const backgroundData = response.included.find(
            (incl) => incl.type === 'background',
        );

        const memorialBookData = (response.included as IMemorialBookDto[]).find(
            (incl) => incl.type === 'memorial_book',
        );

        if (memorialBookData) {
            yield put(memorialBookDataReceived(memorialBookData));
        }

        if (deceasedData) {
            // TODO: Map first and pass only required data instead action creator
            yield put(deceasedDataReceived(response));
        }

        yield put(
            deceasedDisplayImagesReceived({
                backgroundID: get(backgroundData, 'id', null),
                avatarSrc: get(
                    deceasedData,
                    'attributes.photoOriginalUrl',
                    null,
                ),
            }),
        );

        yield put(
            pageSettingsDataRetrieved({
                death: response.data.attributes.deathPlaceVisible,
                birth: response.data.attributes.birthPlaceVisible,
                images:
                    response.data.attributes
                        .imageUploadingForCondolencesEnabled,
            }),
        );

        yield put(
            pagePrivacyModeRetrieved(response.data.attributes
                .privacy as PrivacyMode),
        );

        yield put(
            pagePublishedStatusRetrieved({
                published: response.data.attributes.published,
            }),
        );

        yield put(
            tributeContentReceived({
                content: response.data.attributes.tributeText || '',
            }),
        );

        yield put(
            obituaryReceived({
                obituaryText: response.data.attributes.obituaryText || '',
            }),
        );

        yield put(themeReceived(response.data.attributes.theme || ''));

        yield put(vgReceived(response.data.attributes.vgOrderable || false));

        yield put(
            notificationMessageTextReceived({
                text: response.data.attributes.notificationText || '',
            }),
        );

        yield put(
            languageRetrieved({
                language: {
                    code: response.data.attributes
                        .locale as SupportedLanguageCode,
                },
            }),
        );

        yield put(
            memorialBookEnabledFlagReceived(
                response.data.attributes.memorialBookEnabled || false,
            ),
        );

        yield put(fetchPageActions.success(response));
    } catch (err) {
        yield put(fetchPageActions.failure(err.statusText));
    }
}
